import React from 'react';
import PropTypes from 'prop-types'
import GatsbyLink from 'gatsby-link';
import { LocaleContext } from '../components/layout/layout';
import { Container, Section } from '../styles/common/Layout';
import { H1, P } from '../styles/common/Typo';
import ContactSection from '../components/Sections/Contact';
import { Tag, TagWrapper } from '../styles/common/Elements';
import { SEO } from '../components';


const KontaktPage = ({ data, pageContext: { locale }, location }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  return (
    <>
      <SEO
        title={`${i18n.pageContact} | ${i18n.defaultTitleAlt}`}
        pathname={location.pathname}
        locale={locale} />
      <Section id="section__contact" Color="White">
        <Container>
          <div className="flex flex-col lg:flex-row">
            <div className="py-6 w-full lg:w-3/6">
              <H1>{i18n.contact.headline}</H1>
              <P dangerouslySetInnerHTML={{ __html: i18n.contact.address }} />
              <P dangerouslySetInnerHTML={{ __html: i18n.contact.contactDetails }} />
            </div>
            <div className="p-0 md:p-6 w-full lg:w-3/6 flex flex-col text-left">
              <H1>COVID 19</H1>
              <P dangerouslySetInnerHTML={{ __html: i18n.contact.covidText }} />
              <P dangerouslySetInnerHTML={{ __html: i18n.contact.covidText2 }} />
              <TagWrapper FlexStart>
                <Tag NoFirstMargin>WhatsApp</Tag>
                <Tag>Skype</Tag>
                <Tag>Zoom</Tag>
                <Tag>Teamviewer</Tag>
                <Tag>{i18n.contact.covidTextTagEnd}</Tag>
              </TagWrapper>
              <P>{i18n.contact.covidTextBye}</P>
            </div>
          </div>
        </Container>
      </Section>
      <ContactSection />
    </ >
  );
};
export default KontaktPage;
